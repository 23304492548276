// src/components/Info.js
import { Box, Divider, Flex, HStack, Icon, Image, Link, Text, VStack, keyframes } from "@chakra-ui/react";
import pune from "./img/Pune.png";
import maharastra from "./img/maharastra.jpg";
import newMap from "./img/NewMap.jpg";
import { forwardRef } from "react";
import { BiCheckDouble } from "react-icons/bi";

import { ImHappy } from "react-icons/im";
import { HiOutlineEmojiHappy } from "react-icons/hi";

import { MdOutlineWheelchairPickup } from "react-icons/md";
import { FaHandsHelping } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";

import { RiHandHeartLine } from "react-icons/ri";
import { PiHandHeartDuotone } from "react-icons/pi";

import { HiOutlineClipboardList } from "react-icons/hi";

// For Luggage..
import { GiHandTruck } from "react-icons/gi";
import { LiaPrayingHandsSolid } from "react-icons/lia";
import { MdInfoOutline } from "react-icons/md";
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaTruckMedical } from "react-icons/fa6";
import { LuExternalLink } from "react-icons/lu";

// Define the bounce keyframes
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

// Define the gradient animation keyframes
const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Define the wave-like gradient animation keyframes
const waveGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const InfoSection = forwardRef((props, ref) => {
  const travelIconColor = "teal.400";
  const bannerColor = "gray.100";
  return (
    <Box>
      <Flex justifyContent={"center"} wrap="wrap" bgColor="yellow.200">
        <Text fontSize="2xl" fontWeight="bold" color="gray.800" marginRight={{lg:"0px", md:"10px", sm:"10px"}}
        marginLeft={{lg:"0px", md:"10px", sm:"10px"}}>
          Your city not covered yet,{" "}
          <Link href="/demand" color="blue">
            do let us know which city you want us to cover next <Icon as={LuExternalLink} mb="-3px" alignSelf={"center"}/>.
          </Link>
          {" "}
        </Text>
      </Flex>
      <Flex
        sx={{
          // background: "linear-gradient(135deg, #e0f7fa, #80deea, #26c6da)", // blue
          // background: "linear-gradient(135deg, #00c6ff, #0072ff, #00c6ff, #00c6ff)", // ocean blue
          // background: "linear-gradient(135deg, #ff9a9e, #fad0c4, #fad0c4, #ff9a9e)", // sunrise orange - more pinkish.
          background: "linear-gradient(135deg, #11998e, #38ef7d, #43cea2, #185a9d)",
          backgroundSize: "200% 200%",
          animation: `${waveGradient} 5s ease infinite`, // Apply gradient animation
          justifyContent: "center",
          flexWrap: "wrap",
          maxHeight: { lg: "200px", base: "" },
        }}
      >
        <Image
          src={newMap}
          maxWidth={{ base: "100%", md: "50%" }}
          maxHeight="190px"
          mt={{ lg: "0px", base: "20px" }}
        ></Image>
        <Flex
          p={8}
          w={{ lg: "60%", base: "100%" }}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <Flex
            justify="center"
            wrap="wrap"
            gap={{ lg: "2", base: "0" }}
            sx={{
              animation: `${bounce} 2s infinite`, // Apply bounce animation
            }}
          >
            <Text fontSize="4xl" fontWeight="bold">
              {/* Expanding Soon, Currently serving Pune */}
              🚀 We are available in more places now.!
            </Text>
          </Flex>
          <Text fontSize="2xl" fontWeight="bold" mb={4} color="blue">
            {" "}
            <Text fontSize="3xl" fontWeight="bold" color={bannerColor} display="inline">
              Patna
            </Text>{" "}
            and{" "}
            <Text fontSize="3xl" fontWeight="bold" color={bannerColor} display="inline">
              Bengaluru
            </Text>{" "}
            are now covered by our services along with{" "}
            <Text fontSize="3xl" fontWeight="bold" color={bannerColor} display="inline">
              {" "}
              Maharastra!
            </Text>
          </Text>

          {/* <Text maxW="800px">
            Now offering our services throughout the entire state, bringing convenience and care closer to you.
          </Text> */}
          {/* <Text maxW="800px">
            At the moment, our services are{" "}
            <Text as="span" fontWeight="bold">
              {" "}
              available in and around Pune
            </Text>
            . We are actively working to expand our reach and will keep updating here as we broaden our service areas.
          </Text> */}
        </Flex>
      </Flex>
      <Flex justify="center" wrap="wrap" mt="80px" gap="10" ref={ref} minH="400px">
        <Box w={{ lg: "40%", base: "100%" }}>
          <VStack gap="4">
            <Text fontSize="3xl" fontWeight="bold">
              Services for Medical Help{" "}
            </Text>
            <HStack>
              <Icon as={HiOutlineEmojiHappy} color="teal.400" boxSize={"100px"} mr="10px" strokeWidth="1" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Warm Welcome:{" "}
                </Text>
                <Text fontSize="sm">
                  vKutumb's Mitra will greet your family members at their doorstep, ensuring they feel comfortable and
                  cared for from the very start.
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Icon as={MdOutlineWheelchairPickup} color="teal.400" boxSize={"100px"} mr="10px" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Easy Mobility:{" "}
                </Text>
                <Text fontSize="sm">
                  We’ll arrange for a wheelchair if needed, making it easier for your family members to move around the
                  hospital with ease and comfort.
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Icon as={FaRegHandshake} color="teal.400" boxSize={"100px"} mr="10px" transform="scale(0.7)" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Helpful Guidance:{" "}
                </Text>
                <Text fontSize="sm">
                  vKutumb’s Mitra will provide all the essential details, including appointment times and hospital
                  directions, to ensure your family member feels confident and well-informed throughout their visit.
                </Text>
              </Box>
            </HStack>

            <HStack>
              <Icon
                as={PiHandHeartDuotone}
                color="teal.400"
                boxSize={"100px"}
                mr="10px"
                transform="scale(0.8)"
                strokeWidth="0.8"
              />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Smooth Goodbye and Return:{" "}
                </Text>
                <Text fontSize="sm">
                  After their visit, vKutumb’s Mitra will offer a friendly farewell and ensure your family members are
                  safely and comfortably transported back home.
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Icon as={HiOutlineClipboardList} color="teal.400" boxSize={"100px"} mr="10px" strokeWidth="1" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Visit Summary:{" "}
                </Text>
                <Text fontSize="sm">
                  We will provide a straightforward report summarizing what happened during the visit and any important
                  follow-up steps to keep everything on track.
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Box>
        {/* <Divider orientation="vertical" h="80%" borderWidth="2px" borderColor="teal.300" borderStyle="solid" alignSelf="center"/> */}
        <Box w={{ lg: "40%", base: "100%" }}>
          <VStack gap="4">
            <Text fontSize="3xl" fontWeight="bold">
              Services for Travel Help{" "}
            </Text>
            <HStack>
              <Icon as={LiaPrayingHandsSolid} color={travelIconColor} boxSize={"100px"} mr="10px" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Meet and Greet:{" "}
                </Text>
                <Text fontSize="sm">
                  Experience a warm welcome with vKutumb’s Mitra, who will be there to assist your family member from
                  the moment they arrive at the station, ensuring a smooth and comforting start to their journey.
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Icon as={MdOutlineWheelchairPickup} color={travelIconColor} boxSize={"100px"} mr="10px" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Wheelchair Assistance:{" "}
                </Text>

                <Text fontSize="sm">
                  Enjoy peace of mind knowing that vKutumb’s Mitra will arrange wheelchair rental and offer supportive
                  navigation through the station, making travel easier and more comfortable.
                </Text>
              </Box>
            </HStack>

            <HStack>
              <Icon as={GiHandTruck} color={travelIconColor} boxSize="100px" mr="10px" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Luggage Handling:{" "}
                </Text>
                <Text fontSize="sm">
                  Relax and let vKutumb’s Mitra take care of the luggage. We'll coordinate with local porters to handle
                  and store belongings, so your family member can travel without any hassle.
                </Text>
              </Box>
            </HStack>

            <HStack>
              <Icon as={MdInfoOutline} color={travelIconColor} boxSize="100px" mr="10px" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Information and Guidance:{" "}
                </Text>
                <Text fontSize="sm">
                  Trust vKutumb’s Mitra to provide all the essential details, from train schedules to platform numbers,
                  ensuring your family member feels confident and well-informed throughout their journey.
                </Text>
              </Box>
            </HStack>

            <HStack>
              <Icon as={FaTruckMedical} color={travelIconColor} boxSize="100px" mr="10px" />
              <Box w="70%">
                <Text as="span" fontWeight="bold">
                  Emergency Assistance:{" "}
                </Text>
                <Text fontSize="sm">
                  With vKutumb’s Mitra, immediate and compassionate help is always available in case of a medical
                  emergency or any other issue, giving you and your loved ones peace of mind.
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Box>
        <Box bg="teal.100" w="50%" p="20px" borderRadius="lg" mb="20px">
          <Text as="i" fontSize="md" w={{ lg: "80%", base: "100%" }} mt="20px" m="10px">
            <Text as="span" fontWeight="bold">
              Coming Soon - Emergency Support:
            </Text>{" "}
            If any emergencies or issues arise, vKutumb’s Mitra will be available to provide prompt and compassionate
            assistance, ensuring you and your family have peace of mind throughout the process.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
});
