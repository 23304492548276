import React, { useEffect } from "react";
import Header from "./Header";
import { Helmet } from "react-helmet-async";
import { Box, Flex } from "@chakra-ui/react";
import Footer from "./Footer";

function Partner() {
  useEffect(() => {
    const script1 = document.createElement("script");

    script1.src = "https://js.hsforms.net/forms/embed/v2.js";
    script1.async = true;

    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `hbspt.forms.create({
            portalId: "48715859",
            formId: "8a468434-3c84-4062-a04f-0acc45d8821c"
          });`;

    document.body.getElementsByClassName("container")[0].appendChild(script2);
    // document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  return (
    <>
      <Header />
      <Flex paddingTop={"20px"} shadow={"md"} padding={"20px"} flexDirection={"column"} borderRadius={"md"}>
        <Box
          className="container"
          width={{lg:"600px", md:"80%", sm:"90%"}}
          marginTop="20px"
          alignSelf={"center"}
          // style={{ zIndex: -1 }}
          // bgColor="green"
        ></Box>
        <Box
          height={"80px"}
          bgColor="gray.50"
          marginTop="-60px"
          width={{lg:"600px", md:"80%", sm:"90%"}}
          alignSelf={"center"}
          style={{ zIndex: 1 }}
          // marginBottom={"20px"}
        ></Box>
      </Flex>

      <Footer />
    </>
  );
}

export default Partner;
