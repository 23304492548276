// src/components/Header.js
import React, { useContext } from "react";
import {
  Box,
  Flex,
  Link,
  Spacer,
  Button,
  Text,
  HStack,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  useDisclosure,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import logo from "../../assets/img/logo.jpg";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useAuthContext } from "../../hooks/useAuthContext";
import { MdLogout } from "react-icons/md";
import { useLogout } from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Flex
      as="header"
      w="100%"
      justify="space-between"
      align="center"
      bg="teal.700"
      p="10px 20px"
      color="white"
    >
      <Box>
        <Link href="/home">
          <Image src={logo} alt="vKutumb Logo" height="40px" />
        </Link>
      </Box>
      <Flex as="nav" width="100%" justify="space-between" ml="20px">
        <HStack
          as="ul"
          listStyleType="none"
          spacing="20px"
          m="0"
          p="0"
          className="nav-left"
          display={{ sm: "none", md: "flex" }}
        >
          <Link href="/user/dashboard">Book</Link>
          <Link href="/pricing">Pricing</Link>
          <Link href="/aboutus">About Us</Link>
          <Link href="/contactus">Contact Us</Link>
          <Link href="/blogs">Blogs</Link>
          <Link href="/partner" bgColor={"teal.100"} color={"#FB4F14"} borderRadius={8} padding={2} fontStyle="italic" fontWeight={"bold"}>Partner with Us!</Link>
        </HStack>
        <Spacer />
        <HStack
          as="ul"
          listStyleType="none"
          spacing="20px"
          m="0"
          p="0"
          className="nav-right"
        >
          {user ? (
            <>
              {" "}
              <Text>Welcome {user.name}</Text>
              <Divider mt="10px" mb="10px" orientation="vertical" display={{ sm: "none", md: "flex" }}/>
              <Button
                ms="0px"
                px="0px"
                me={{ sm: "2px", md: "16px" }}
                variant="transparent-with-icon"
                leftIcon={<MdLogout />}
                onClick={handleLogout}
                display={{ sm: "none", md: "flex" }}
              >
                <Text display={{ sm: "none", md: "flex" }}>Logout</Text>
              </Button>
            </>
          ) : (
            <>
              <Link href="/login">Log in</Link>
              <Link href="/signup">Sign up</Link>
            </>
          )}

          <IconButton
            display={{ base: "flex", md: "none" }}
            icon={<HamburgerIcon />}
            aria-label="Open Menu"
            onClick={onOpen}
          />
        </HStack>
      </Flex>

      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bgColor={"teal.700"} color={"white"}>Menu</DrawerHeader>
          <DrawerBody>
            <VStack align="start">
              if(user && (
              <Box>
                <Text>Welcome {user && user.name}</Text>
                <Divider mt="10px" mb="10px" />
              </Box>
              ) )
              <Link href="/user/dashboard" onClick={onClose}>
                Book
              </Link>
              <Link href="/pricing" onClick={onClose}>
                Pricing
              </Link>
              <Link href="/aboutus" onClick={onClose}>
                About Us
              </Link>
              <Link href="/contactus" onClick={onClose}>
                Contact Us
              </Link>
              <Divider />
              {!user && (
                <HStack>
                  <Link href="/login">
                    <Button colorScheme={"teal"} bgColor="teal.600">
                      Log in
                    </Button>
                  </Link>
                  <Link href="/signup">
                    <Button colorScheme={"teal"} bgColor="teal.600">
                      Sign up
                    </Button>
                  </Link>
                </HStack>
              )}
              {user && (
                <Button
                  me={{ sm: "2px", md: "16px" }}
                  variant="solid"
                  bgColor={"orange.200"}
                  leftIcon={<MdLogout />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Header;

//<Flex
// as="header"
// bg="teal.600"
// color="black"
// p={4}
// align="center"
// borderBottom="1px solid #E2E8F0"
// >
// <Text fontSize="2xl" fontWeight="bold">
//   Uber
// </Text>
// <Spacer />
// <Flex gap={6}>
//   <Link href="#">Company</Link>
//   <Link href="#">Products</Link>
//   <Link href="#">Safety</Link>
//   <Link href="#">Help</Link>
//   <Button colorScheme="blackAlpha" variant="outline">
//     Sign in
//   </Button>
// </Flex>
// </Flex>
