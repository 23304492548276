import React, { useEffect } from "react";
import Header from "./Header";
import { Helmet } from "react-helmet-async";
import { Box, Flex } from "@chakra-ui/react";
import Footer from "./Footer";

function CityDemand() {
  useEffect(() => {
    const script2 = document.createElement("script");
    script2.innerHTML = `hbspt.forms.create({
            portalId: "48715859",
            formId: "9279c5f7-cd34-4425-8f34-51a10d803b02"
          });`;

    document.body.getElementsByClassName("container")[0].appendChild(script2);
    // document.body.appendChild(script2);

    return () => {
      document.body.getElementsByClassName("container")[0].removeChild(script2);
    };
  }, []);


  return (
    <>
      <Header />
      <Flex paddingTop={"20px"} shadow={"md"} padding={"20px"} flexDirection={"column"} borderRadius={"md"}>
        <Box
          className="container"
          width={{lg:"600px", md:"80%", sm:"90%"}}
          marginTop="20px"
          alignSelf={"center"}
          // style={{ zIndex: -1 }}
          // bgColor="green"
        ></Box>
        <Box
          height={"80px"}
          bgColor="gray.50"
          width={{lg:"600px", md:"80%", sm:"90%"}}
          marginTop="-60px"
          alignSelf={"center"}
          style={{ zIndex: 1 }}
          // marginBottom={"20px"}
        ></Box>
      </Flex>

      <Footer />
    </>
  );
}

export default CityDemand;
