// src/components/Services.js
import ourProcess from "../../assets/img/vKutumbProcess.png";
import collage1 from "./img/Collage1.jpeg";
import collage2 from "./img/Collage2.jpeg";
import collage3 from "./img/Collage3.jpeg";
// src/components/ServiceCards.js
import React, { forwardRef } from "react";
import { Box, Flex, Text, Stack, Image, Button, Center } from "@chakra-ui/react";

const services = [
  {
    title: "Process Flow for vKutumb Help  ",
    description:
      "With just one booking, you can ensure that your family members receive the medical support they need. We complement the care provided by doctors by offering additional assistance when you are unavailable, much like a supportive family member would",
    image: ourProcess,
    button: "Get Started",
  },
  {
    title: "No matter what vKutumb is always there for you",
    description: "Many have already used vKutumb and they are very happy with the high quality service provided",
    image: collage1,
  },
  {
    title: "vKutumb : A family that cares for you and yours",
    description: "We take complete care of the relative from the moment of pick-up to the moment of drop",
    image: collage3,
  },
];

const ServiceCards = ()=> {
  return (
    <Box paddingY="16" bg="white">
      <Stack spacing="2">
        {services.map((service, index) => (
          <Flex
            key={index}
            direction={{ md: index % 2 === 0 ? "row-reverse" : "row", base: "row-reverse" }} // Alternates the direction
            align="center"
            justify="center"
            gap={{ lg: "20", md: "0" }}
            flexWrap={"wrap"}
            mb={{ base: "50px", md: "0px" }}
          >
            {/* Text Section */}
            <Box maxWidth="500px" ml="20px" mb={{ base: "20px", md: "0px" }}>
              <Text fontSize="3xl" fontWeight="bold" mb="6">
                {service.title}
              </Text>
              <Text fontSize="lg">{service.description}</Text>
              {/* Button */}
              {service.button && (
                <Center h="100px" color="white">
                  <Button
                    type="submit"
                    bg="teal.600"
                    color="white"
                    width="150px"
                    onClick={() => (window.location.href = "/user/dashboard")}
                  >
                    {service.button}
                  </Button>
                </Center>
              )}
            </Box>

            {/* Image Section */}
            <Box maxWidth="500px">
              <Image src={service.image} alt={service.title} boxSize="100%" objectFit="cover" borderRadius="lg" />
            </Box>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default ServiceCards;
