import "./App.css";
import MainLayout from "./layouts/MainLayout";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";

import F404 from "./pages/P404";
import PrivateRoutes from "./components/PrivateRoutes";
import SignIn from "views/Auth/SignIn";
import SignUp from "views/Auth/SignUp";
import Relatives from "pages/Relatives/RelativesTable";
import Home2 from "./pages/Home/Home2";
import ContactUs from "./pages/Home/ContactUs";
import AboutUs from "./pages/Home/AboutUs2";
import MedicalForm from "./views/MedicalForm";
import { useAuthContext } from "./hooks/useAuthContext";
import Pricing from "./pages/Home/Pricing";
import BlogList from "./pages/Blogs/BlogList";
import BlogPost from "./pages/Blogs/BlogPost";
import EditBlog from "./pages/Blogs/EditBlog";
import CreateReportPage from "./pages/CreateReport";
import WebsiteVisitorTable from "./pages/WebsiteVisitor";
import RelativeList from "./pages/Relatives/RelativeList";
import { Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton } from "@chakra-ui/react";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useLogout } from "./hooks/useLogout";
import RequestResetPassword from "./views/Auth/RequestResetPassword";
import VerifyOTP from "./views/Auth/VerifyResetPassword";
import ResetPasswordForm from "./views/Auth/ResetPasswordForm";
import ValidateToken from "./views/Auth/ValidateToken";
import AuthLayout from "./views/Auth/AuthLayout";
import AccountSettings from "./pages/profile/AccountSettings";
import PricingPlans from "./pages/Home/Pricing_temp";
import TermsAndConditions from "./pages/Home/TermsAndConditions";
import Partner from "./pages/Home/Partner";
import CityDemand from "./pages/Home/CityDemand";

// import AllBookingTable from "./admin/bookings/viewAllBookings";

function App() {
  const AuthProviderLayout = () => {
    const [alert, setAlert] = useState({ isOpen: false, message: "" });
    const { user } = useAuthContext();
    const { logout } = useLogout();
    const navigate = useNavigate();

    // Token expiration check
    if (user && user.token && !alert.isOpen) {
      const decodedToken = jwtDecode(user.token);
      const currentTime = Date.now() / 1000; // Current time in seconds

      if (decodedToken.exp < currentTime) {
        // Token is expired, clear user, set alert, and navigate to login
        console.log("Token expired");
        setAlert({
          isOpen: true,
          message: "You have been logged out due to inactivity. Please log in again.",
        });
      }
    }

    function handleAlertClose() {
      setAlert({ isOpen: false, message: "" });
      logout();
      navigate("/login");
    }

    return (
      <>
        {alert.isOpen && (
          <Alert
            status="warning"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon />
            <AlertTitle mr={2}>Warning!</AlertTitle>
            <AlertDescription>{alert.message}</AlertDescription>
            <CloseButton onClick={handleAlertClose} position="absolute" right="8px" top="8px" />
          </Alert>
        )}
        {!alert.isOpen && <Outlet />}
      </>
    );
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthProviderLayout />}>
        {/* Public Routes */}
        <Route path="/" element={<Home2 />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/home" element={<Home2 />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/tnc" element={<TermsAndConditions />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/demand" element={<CityDemand />} />
        {/* <Route path="/pricingt" element={<PricingPlans />} /> */}

        {/* <Route path="/blogs" element={<BlogList />} />
        <Route path="/blogs/:id" element={<BlogPost />} /> */}

        <Route element={<AuthLayout />}>
          {/* Step 1: User requests reset link */}
          <Route path="/forgot-password" element={<RequestResetPassword />} />

          {/* Step 2: User enters OTP */}
          <Route path="/verify-otp" element={<VerifyOTP />} />

          <Route path="/validate-token" element={<ValidateToken />} />

          {/* Step 3: User resets the password via the link */}
          <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/user" element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="editBlog" element={<EditBlog />} />
            <Route path="editBlog/:id" element={<EditBlog />} />
            <Route path="createReport" element={<CreateReportPage />} />
            <Route path="websitevisitor" element={<WebsiteVisitorTable />} />
            <Route path="profile" element={<AccountSettings />} />

            {/* <Route path="allbookings" element={<AllBookingTable />} /> */}
            <Route
              path="manageRelatives"
              element={
                // <RelativeList/>
                <Relatives />
              }
            />
            <Route path="medicalform" element={<MedicalForm />} />
          </Route>
        </Route>
        {/* <Route path="*" element={<F404 />} /> */}
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
